export default class Util {
  constructor() {}

  sortColumns(arr, sortBy, asc) {
    arr.sort((a, b) => {
      let A = null
      let B = null

      if (sortBy == 'role') {
        if (a.roles.length > 1) {
          a.roles.forEach((key) => {
            if (key.role !== 'driver' && key.role !== 'acc_admin')
              A = key.role.toLowerCase()
            if (key.role == 'acc_admin') A = 'manager'
          })
        }

        if (a.roles.length === 1) A = a.roles[0].role

        if (b.roles.length > 1) {
          b.roles.forEach((key) => {
            if (key.role !== 'driver' && key.role !== 'acc_admin')
              B = key.role.toLowerCase()
            if (key.role == 'acc_admin') B = 'manager'
          })
        }

        if (b.roles.length === 1) B = b.roles[0].role
      } else if (sortBy == 'lastLogin') {
        A = new Date(a[sortBy]).getTime()
        B = new Date(b[sortBy]).getTime()
      } else if (sortBy === 'trailer' || sortBy == 'truck') {
        if (a[sortBy].length > 1) A = 'multiple'
        if (b[sortBy].length > 1) B = 'multiple'
        if (a[sortBy].length === 1) A = a[sortBy][0].id
        if (b[sortBy].length === 1) A = b[sortBy][0].id
      } else if (sortBy === 'userCount' || sortBy === 'documentCount') {
        A = a[sortBy]
        B = b[sortBy]
      } else {
        A = a[sortBy] ? a[sortBy].toLowerCase() : ''
        B = b[sortBy] ? b[sortBy].toLowerCase() : ''
      }

      if (asc == 'up') {
        return A < B ? -1 : A > B ? 1 : 0
      }

      if (asc !== 'up') {
        return A > B ? -1 : A < B ? 1 : 0
      }
    })
  }

  getArrayChanges(oldArray, newArray) {
    if (!oldArray) oldArray = []
    if (!newArray) newArray = []
    let added = []
    for (let i = 0; i < newArray.length; i++) {
      let found = false
      for (let x = 0; x < oldArray.length; x++) {
        if (oldArray[x].id === newArray[i].id) {
          found = true
          break
        }
      }

      if (!found) {
        added.push(newArray[i])
      }
    }

    let removed = []
    for (let x = 0; x < oldArray.length; x++) {
      let found = false

      for (let i = 0; i < newArray.length; i++) {
        if (oldArray[x].id === newArray[i].id) {
          found = true
          break
        }
      }

      if (!found) {
        removed.push(oldArray[x])
      }
    }

    return {
      added: added,
      removed: removed
    }
  }

  isExpiring(date) {
    if (date.toLowerCase() === 'none' || date.toLowerCase() === '') {
      return false
    }
    const day = 1000 * 60 * 60 * 24
    const today = new Date().getTime()
    const expDate = new Date(date).getTime()
    const comparison = Math.ceil((expDate - today) / day)

    if (comparison > 0 && comparison <= 30) {
      return true
    }

    return false
  }

  isExpired(date) {
    if (!date) {
      return false
    }
    if (date.toLowerCase() === 'none' || date.toLowerCase() === '') {
      return false
    }

    const today = new Date().getTime()
    const expDate = new Date(date).getTime()

    if (expDate <= today) {
      return true
    }

    return false
  }

  static sortByKey(arr, key, asc) {
    try {
      const sortedArray = arr.sort((a, b) => {
        try {
          const A = a[key]
          const B = b[key]
          if (asc) {
            if (A > B) return -1
            if (A < B) return 1
          }

          if (!asc) {
            if (A < B) return -1
            if (A > B) return 1
          }
          return 0
        } catch (err) {
          console.log(err)
        }
      })
      return sortedArray
    } catch (err) {
      console.log(err)
    }
  }
}
