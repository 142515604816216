var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-navbar"},[(!_vm.isWelcomeNavbar)?_c('div',[_c('div',{staticClass:"navbar-logo",on:{"click":function($event){return _vm.goHome()}}},[_vm._v("Home")]),(!_vm.loggedOut && _vm.insideCompany)?_c('div',{staticClass:"org-admin-tabs"},[_c('div',{staticClass:"option",class:{ selected: _vm.management },on:{"click":_vm.selectManagement}},[_vm._v(" Management ")]),_c('div',{staticClass:"option",class:{ selected: !_vm.management },on:{"click":_vm.selectDocument}},[_vm._v(" Documents ")])]):_vm._e()]):_vm._e(),(_vm.loggedOut && !_vm.isWelcomeNavbar)?_c('div',{staticClass:"nav"},[_c('button',{staticClass:"link button button-secondary",on:{"click":_vm.showSupport}},[_vm._v(" Support ")]),(_vm.loggedOut)?_c('router-link',{attrs:{"to":"/login"}},[_c('button',{staticClass:"link button button-primary"},[_vm._v("Sign In")])]):_c('router-link',{attrs:{"to":"/login"}},[_c('button',{staticClass:"link button button-primary"},[_vm._v("Admin")])])],1):_vm._e(),(
      !_vm.loggedOut &&
      !_vm.isSuperAdmin &&
      !_vm.hideCompanySelector &&
      !_vm.isWelcomeNavbar &&
      _vm.hasMultipleCompanies
    )?_c('div',{staticClass:"company-selector"},[_c('multiselect',{staticClass:"multi-select company-selector-multi-select",attrs:{"label":"name","track-by":"id","selectLabel":"","deselectLabel":"","selectedLabel":"","options":_vm.myCompanies.options,"group-values":"companies","group-label":"label","group-select":false,"option-width":80,"option-height":20,"searchable":false,"allowEmpty":false,"open-direction":"bottom"},on:{"input":_vm.myCompanyChanged},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__title"},[_c('span',{staticClass:"viewing__label"},[_vm._v("VIEWING:")]),_c('span',{staticClass:"company__title"},[_vm._v(_vm._s(props.option.name))])]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/multiselect-arrow.png")}})]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title",class:props.option.$groupLabel
                ? 'group'
                : 'item ' + props.option.status,attrs:{"id":props.option.$groupLabel
                ? ''
                : 'select-company-' + props.option.id}},[_vm._v(" "+_vm._s(props.option.$groupLabel ? props.option.$groupLabel : props.option.name)+" ")])])]}}],null,false,386712479),model:{value:(_vm.myCompanies.selected),callback:function ($$v) {_vm.$set(_vm.myCompanies, "selected", $$v)},expression:"myCompanies.selected"}})],1):_vm._e(),(!_vm.loggedOut && !_vm.isWelcomeNavbar)?_c('div',{staticClass:"nav"},[_c('div',{staticClass:"menu",on:{"click":function($event){$event.preventDefault();return _vm.$refs.navDropdown.changeMenuStatus()}}},[_c('div',{staticClass:"dropdown-arrow",attrs:{"aria-label":"Profile Menu Dropdown"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"6","viewBox":"0 0 10 6"}},[_c('path',{attrs:{"fill":"#FFF","fill-rule":"evenodd","d":"M10 .952L5 6 0 .952.943 0 5 4.096 9.057 0z"}})])]),_c('p',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.fullName))])]),_c('dropdown',{ref:"navDropdown",attrs:{"top":"40px","left":"-59px"}},[_c('p',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.openAccount()}}},[_vm._v("Account")]),_c('p',{staticClass:"dropdown-item",on:{"click":_vm.logOut}},[_vm._v("Sign Out")])])],1):_vm._e(),(_vm.isWelcomeNavbar)?_c('div',{staticClass:"welcome-navbar"},[_c('div',{staticClass:"navbar-logo"},[_vm._v("Home")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }