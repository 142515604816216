export default class SubscriptionStore {
  constructor(client, events) {
    this.$client = client
    this.$events = events
  }

  async getCurrentPlanOffering() {
    let plan = await this.$client.getPlanOffering()
    if (!plan) return

    let parsedPlan = {
      options: []
    }

    let from = 0
    for (let i = 0; i < plan.tiers.length; i++) {
      if (plan.tiers[i].up_to) {
        let option = {
          from: from,
          to: plan.tiers[i].up_to,
          price: plan.tiers[i].flat_amount
        }
        parsedPlan.options.push(option)
        from = option.to + 1
      } else {
        let option = {
          from: from + '+',
          to: null,
          price: plan.tiers[i].flat_amount
        }
        parsedPlan.options.push(option)
      }
    }

    return parsedPlan
  }

  async createSubscription(token, companyId, initialCharge) {
    await this.$client.createSubscription(token, companyId, initialCharge)
  }

  async cancelSubscription(companyId) {
    await this.$client.cancelSubscription(companyId)
  }

  async updatePaymentMethod(token) {
    await this.$client.updatePaymentMethod(token)
  }

  async loadAllInvoices() {
    return await this.$client.readInvoices()
  }

  async getTopLevelData(userId) {
    let data = {
      numDrivers: 0
    }

    // First figure out which company this user is an org admin for
    let userRoles = await this.$client.readRoles(userId)
    let companyId
    for (let i = 0; i < userRoles.length; i++) {
      if (userRoles[i].role === 'org_admin') {
        companyId = userRoles[i].companyId
        break
      }
    }
    if (!companyId) {
      throw new Error('You do not have permission to view billing information.')
    }

    // Get the number of drivers
    let userList = await this.$client.readUsers({
      companyId: companyId,
      role: 'driver'
    })

    // Get subscription info
    let sub = await this.$client.readSubscription(companyId)

    if (sub.data.length > 0) {
      let subscriptionEntry = sub.data[0]
      data.subscription = {
        status: subscriptionEntry.status,
        cancelled: !subscriptionEntry.stripeSubscriptionId
      }

      // The service returns up to 6 invoices initially. If 6 is returned, trim the list to 5 and
      // indicate that more can be loaded
      if (!subscriptionEntry.latestInvoices) {
        subscriptionEntry.latestInvoices = []
      }
      if (subscriptionEntry.latestInvoices.length === 6) {
        subscriptionEntry.latestInvoices =
          subscriptionEntry.latestInvoices.splice(5, 1)
        data.hasMoreInvoices = true
      }
      data.latestInvoices = subscriptionEntry.latestInvoices

      if (
        subscriptionEntry.stripeSubscriptionData &&
        subscriptionEntry.stripeSubscriptionData.upcomingInvoice
      ) {
        data.nextBill =
          subscriptionEntry.stripeSubscriptionData.upcomingInvoice.amount_due ||
          0
      } else {
        data.nextBill = 0
      }

      let sources = subscriptionEntry.stripeCustomer.sources
      if (sources && sources.data) {
        if (sources.data.length > 0) {
          if (sources.data[0].last4) {
            data.last4 = sources.data[0].last4
          }
        }
      }
    } else {
      data.nextBill = 0
    }

    data.numDrivers = userList.data.length
    this.$events.$emit('subscription_data', data)
  }
}
