<template>
  <div class="payment-modal vue-modal">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header inverse">
        <h3 class="title">Payment Information</h3>
        <div
          class="close"
          v-show="status !== 'submitting'"
          v-on:click="canceled"
        ></div>
      </div>
      <div class="content" :class="computedFormClass">
        <div class="form">
          <div id="cardAddress">
            <text-input label="Billing address" v-model="address"></text-input>
          </div>

          <div id="cardCity">
            <text-input label="City" v-model="city"></text-input>
          </div>
          <div id="cardState">
            <text-input label="State" v-model="state"></text-input>
          </div>
          <div id="cardZip">
            <text-input label="ZIP" v-model="postalCode"></text-input>
          </div>
          <!--
          <div id="cardZip" class="stripe-element">
            <div id="cardZipField" class="input empty"></div>
            <label for="cardZipField">ZIP</label>
          </div>
          -->

          <div style="clear: both"></div>

          <div id="cardNumber" class="stripe-element">
            <div id="cardNumberField" class="input empty"></div>
            <label for="cardNumberField">Card Number</label>
          </div>
          <div id="cardExpiry" class="stripe-element">
            <div id="cardExpiryField" class="input empty"></div>
            <label for="cardExpiryField">Expiration</label>
          </div>
          <div id="cardCvc" class="stripe-element">
            <div id="cardCvcField" class="input empty"></div>
            <label for="cardExpiryField">CVC</label>
          </div>

          <div style="clear: both"></div>

          <primary-button
            label="Save"
            :class="computedSubmitClass"
            v-bind:clicked="confirm"
          ></primary-button>
        </div>
        <div class="success">
          <div class="icon">
            <svg
              width="88px"
              height="88px"
              viewBox="0 0 88 88"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <circle
                class="border"
                cx="44"
                cy="44"
                r="40"
                stroke-linecap="round"
                stroke-width="6"
                stroke="#000"
                fill="none"
              />
              <path
                class="checkmark"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M28.375 42.5488281 41.8840688 56.0578969 60.891932 33.0500338"
                stroke-width="6"
                stroke="#000"
                fill="none"
              />
            </svg>
          </div>
          <h3 class="title" data-tid="elements_examples.success.title">
            Thank you!
          </h3>
          <p class="message">
            <span data-tid="elements_examples.success.message">
              <span v-show="paymentModal.mode === 'update_card'"
                >Your payment information has been saved.</span
              >
              <span v-show="paymentModal.mode === 'payment'"
                >Your payment was successful.</span
              >
            </span>
          </p>
          <primary-button
            :clicked="submitted"
            v-show="status === 'submitted'"
            label="Close"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'

import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'

const stripe = loadStripe(process.env.VUE_APP_STRIPE_KEY)
let elements
stripe.then((result) => {
  elements = result.elements()
})
let cardNumber, cardExpiry, cardCvc
export default {
  name: 'PaymentModal',
  components: { TextInput, PrimaryButton },
  props: ['paymentModal'],
  data() {
    return {
      status: 'filling',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      stripeNotFilled: {
        postalCode: false, // switched to using non-stripe postal code field for now
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true
      }
    }
  },
  created() {},
  methods: {
    confirm: async function () {
      if (this.formIsDisabled()) return

      this.status = 'submitting'
      let additionalData = {
        address_line1: this.address,
        address_city: this.city,
        address_state: this.state,
        address_zip: this.postalCode
      }
      stripe.createToken(cardNumber, additionalData).then(async (result) => {
        if (result.token) {
          try {
            let companyId = this.paymentModal.companyId
            let selectedTierPrice = this.paymentModal.selectedTierPrice
            if (this.paymentModal.mode === 'update_card') {
              await this.$subscriptionStore.updatePaymentMethod(result.token.id)
            } else {
              await this.$subscriptionStore.createSubscription(
                result.token.id,
                companyId,
                selectedTierPrice
              )
            }
            this.status = 'submitted'
          } catch (err) {
            let message = err.message
            if (err.response && err.response.status === 402) {
              message =
                'Your payment information was declined. Please try again.'
            }
            this.$events.$emit('hidePaymentModal')
            this.$events.$emit('showBasicModal', {
              title: 'Error Processing Payment',
              text: message,
              mode: 'ok',
              confirmText: 'Ok',
              showWarningIcon: true
            })
          }
        } else {
          this.$events.$emit('hidePaymentModal')
          this.$events.$emit('showBasicModal', {
            title: 'Error Processing Payment',
            text: 'Your payment couldn’t be processed for an unknown reason. Please try again later.',
            mode: 'ok',
            confirmText: 'Ok',
            showWarningIcon: true
          })
        }
      })
    },
    canceled: async function () {
      if (this.status === 'submitting') return
      if (this.paymentModal.callback) {
        this.paymentModal.callback(false, this)
      }
      this.$events.$emit('hidePaymentModal')
    },
    submitted: async function () {
      if (this.status === 'submitting') return
      if (this.paymentModal.callback) {
        this.paymentModal.callback(true, this)
      }
      this.$events.$emit('hidePaymentModal')
    },
    formIsDisabled: function () {
      if (
        !this.address ||
        !this.city ||
        !this.state ||
        !this.postalCode ||
        this.stripeNotFilled.postalCode ||
        this.stripeNotFilled.cardNumber ||
        this.stripeNotFilled.cardExpiry ||
        this.stripeNotFilled.cardCvc
      ) {
        return true
      } else {
        return false
      }
    }
  },
  mounted: function () {
    let props = {
      style: {
        base: {
          lineHeight: '67px',
          color: 'rgb(80, 80, 80)',
          fontWeight: 500,
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: 'transparent'
          },
          ':-webkit-autofill': {
            color: 'transparent'
          }
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: '#FFCCA5'
          }
        }
      },
      classes: {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid'
      }
    }

    if (cardNumber) {
      // Reset the form if it has been re-opened

      // cardZip.destroy()
      cardNumber.destroy()
      cardExpiry.destroy()
      cardCvc.destroy()

      this.stripeNotFilled.postalCode = false // Change to true if using stripe again
      this.stripeNotFilled.cardNumber = true
      this.stripeNotFilled.cardExpiry = true
      this.stripeNotFilled.cardCvc = true
    }
    // cardZip = elements.create('postalCode', props)
    cardNumber = elements.create('cardNumber', props)
    cardExpiry = elements.create('cardExpiry', props)
    cardCvc = elements.create('cardCvc', props)

    // cardZip.mount('#cardZipField')
    cardNumber.mount('#cardNumberField')
    cardExpiry.mount('#cardExpiryField')
    cardCvc.mount('#cardCvcField')

    let elementsArray = []
    // elementsArray.push(cardZip)
    elementsArray.push(cardNumber)
    elementsArray.push(cardExpiry)
    elementsArray.push(cardCvc)
    elementsArray.forEach((element) => {
      element.on('change', (event) => {
        let notFilled = false
        if (event.empty || !event.complete) notFilled = true
        this.stripeNotFilled[event.elementType] = notFilled
      })
    })
  },
  computed: {
    computedSubmitClass: function () {
      if (this.formIsDisabled()) {
        return 'disabled'
      } else {
        return ''
      }
    },
    computedFormClass: function () {
      return this.status
    }
  }
}
</script>

<style lang="scss">
.payment-modal {
  .box {
    .content {
      height: 100%;
      padding: 0 69px 22px;
    }

    .text-input {
      width: 100%;
      margin-bottom: 21px;
    }

    .primary-button {
      width: 100%;
      height: 31px;
      margin-top: 29px;
      font-size: 13px;
    }

    .stripe-element {
      height: 60px;
      margin-bottom: 11px;
      border-bottom: solid 1px $greyish-brown;

      label {
        display: block;
        position: absolute;
        left: 2px;
        top: 24px;
        font-family: Barlow;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #505050;
        text-align: left;
        cursor: text;
      }
    }

    #cardAddress {
      margin-top: 10px;
    }

    #cardCity {
      width: 124px;
      float: left;
      margin-right: 15px;
    }
    #cardState {
      width: 44px;
      float: left;
      margin-right: 15px;
    }
    #cardZip {
      width: 90px;
      height: 52px;
      float: left;
    }

    #cardNumber {
      width: 288px;
      height: 52px;
    }

    #cardExpiry {
      width: 142px;
      height: 52px;
      float: left;
      margin-right: 15px;
    }
    #cardCvc {
      width: 131px;
      height: 52px;
      float: left;
    }

    .input:not(.empty) + label {
      top: 0;
      left: 0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.3px;
      color: $greyish-brown;
      text-transform: uppercase;
      cursor: default;
    }

    .form {
      transition-property: opacity, transform;
      transition-duration: 0.35s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .submitted .form,
    .submitting .form {
      opacity: 0;
      transform: scale(0.9);
      pointer-events: none;
    }

    .success {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 10px;
      text-align: center;
      pointer-events: none;
      overflow: hidden;
    }

    @media (min-width: 670px) {
      .success {
        padding: 40px;
      }
    }

    .success > * {
      transition-property: opacity, transform;
      transition-duration: 0.35s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: 0;
      transform: translateY(50px);
    }

    .success .primary-button {
      width: 200px;
      padding: 10px 0 25px;
    }

    .success .icon {
      margin: 15px 0 30px;
      transform: translateY(70px) scale(0.75);
    }

    .success .icon svg {
      will-change: transform;
    }

    .success .icon .border {
      stroke-dasharray: 251;
      stroke-dashoffset: 62.75;
      transform-origin: 50% 50%;
      transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
      animation: spin 1s linear infinite;
    }

    .success .icon .checkmark {
      stroke-dasharray: 60;
      stroke-dashoffset: 60;
      transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1)
        0.35s;
    }

    .success .title {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .success .message {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 25px;
      line-height: 1.6em;
    }

    .success .message span {
      font-size: inherit;
    }

    .submitted .success,
    .submitting .success {
      pointer-events: all;
    }

    .submitting .success .icon {
      opacity: 1;
    }

    .submitted .success > * {
      opacity: 1;
      transform: none !important;
    }

    .submitted .success > :nth-child(2) {
      transition-delay: 0.1s;
    }

    .submitted .success > :nth-child(3) {
      transition-delay: 0.2s;
    }

    .submitted .success > :nth-child(4) {
      transition-delay: 0.3s;
    }

    .submitted .success .icon .border,
    .submitted .success .icon .checkmark {
      opacity: 1;
      stroke-dashoffset: 0 !important;
    }

    .success .icon .border {
      stroke: #eeeeee;
    }

    .success .icon .checkmark {
      stroke: #eeeeee;
    }

    .success .title {
      color: #124e81;
      font-family: Barlow;
      font-size: 24px;
      font-weight: bold;
    }

    .success .message {
      color: #124e81;
      font-family: Barlow;
      font-size: 16px;
    }
  }
}
</style>
