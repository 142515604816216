<template>
  <div class="import-box">
    <input
      ref="myInput"
      type="file"
      class="input"
      title
      accept=".csv"
      v-bind:class="{ disabled: fileAdded }"
      @change="vfileAdded"
    />
    <div id="dropzone" v-bind:class="{ disabled: !fileAdded }">
      <img class="import-arrow" src="../assets/upload.png" v-if="!fileAdded" />
      <img
        class="import-check"
        src="../assets/check.png"
        v-if="fileAdded && !incorrectType"
      />
      <img
        class="import-check"
        src="../assets/wrong-format.png"
        v-if="fileAdded && incorrectType"
      />
      <div class="drop-text" v-if="!fileAdded">Drag CSV File Here</div>
      <div class="drop-text" v-if="fileAdded">
        <div v-if="incorrectType">File format should be .csv</div>
        <div class="drop-text" v-else>File ready for import</div>
        <div v-on:click="newFile" class="link">Replace File</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileAdded: false,
      incorrectType: false,
      file: ''
    }
  },
  created() {},
  computed: {},
  methods: {
    newFile() {
      this.fileAdded = false
      this.$emit('fileAdded', false)
      let elem = this.$refs.myInput
      elem.value = ''
      elem.click()
    },
    vfileAdded(event) {
      var reader = new FileReader()
      reader.onload = (e) => {
        //store somewhere to send to the backend
        this.$emit('file', e.target.result)
      }
      reader.readAsText(event.path[0].files[0])
      this.fileAdded = true
      if (event.path[0].files[0].name.slice(-4) === '.csv') {
        this.incorrectType = false
        this.$emit('fileAdded', true)
      } else {
        this.incorrectType = true
      }
    }
  }
}
</script>

<style lang="scss">
$dropZoneHeight: 207px;
.import-box {
  height: $dropZoneHeight;
  margin-bottom: 24px;

  *:hover {
    cursor: pointer;
  }
  #dropzone {
    width: 100%;
    height: 100%;
    position: absolute;
    border: dashed 2px rgba(18, 78, 129, 0.1);
    border-radius: 9px;
    font-size: 20px;
  }
  .disabled {
    pointer-events: none;
  }
  .input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    color: transparent;
  }
  .import-arrow {
    width: 64px;
    height: 72px;
    margin-top: 33px;
  }
  .import-check {
    width: 72px;
    height: 72px;
    margin-top: 33px;
  }
  .drop-text {
    margin-top: 16px;
    color: #124e81;
    font-size: 22px;
    font-family: Poppins;
    &:hover {
      cursor: pointer;
    }
  }
  .link {
    margin-top: 8px;
    line-height: 18px;
    text-decoration: underline;
    font-size: 15px;
    font-family: Barlow;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
