import Util from './util.js'

export default class CompanyStore {
  constructor(client, events) {
    this.$client = client
    this.$events = events
    this.totalCompanies = 0
    this.companies = []
    this.mapCompanies = new Map()
  }

  async add(companyName, managed) {
    let response = await this.$client.createCompany(companyName, managed)

    await this.loadAll()

    return response.id
  }

  async updateById(companyId, body) {
    await this.$client.updateCompanyById(companyId, body)
  }

  async loadAll(filters, readOnly = false) {
    try {
      let response = await this.$client.readCompanies(filters)

      if (readOnly && filters) {
        let queryArr = filters.query.split(' ')

        response.data.forEach((company) => {
          company.points = 0
          if (filters.query !== '') {
            queryArr.forEach((key) => {
              let docName = company.name.toLowerCase()

              company.points = company.points + (docName.split(key).length - 1)
            })
          }
        })

        return Util.sortByKey(response.data, 'points', true)
      }

      if (!readOnly) {
        this.companies = response.data
        this.totalCompanies = response.totalCompanies

        this.companies.forEach((company) =>
          this.mapCompanies.set(company.id, company)
        )

        this.$events.$emit('companies_refresh', {
          companies: this.companies,
          totalCompanies: this.totalCompanies
        })

        return this.companies
      }
    } catch (err) {
      console.log(err)
    }
  }

  getCompanyNameSync(companyId) {
    let company = this.mapCompanies.get(companyId)
    if (!company) {
      company = {
        name: ''
      }
    }
    return company.name
  }

  async getCompanyName(companyId) {
    if (this.totalCompanies === 0) {
      await this.loadAll()
    }

    let company = this.mapCompanies.get(companyId)
    if (!company) {
      company = {
        name: ''
      }
    }
    return company.name
  }

  async deleteCompanyById(companyId) {
    await this.$client.deleteCompanyById(companyId)

    await this.loadAll()
  }
}
