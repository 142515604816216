export default class AssignmentStore {
  constructor(client, events) {
    this.$client = client
    this.$events = events
    this.totalAssignment = 0
    this.assignment = []
    this.mapAssignment = new Map()
  }

  async add(assignment) {
    let response = await this.$client.createAssignment(assignment)

    return response
  }

  async addAssignment(equipmentId, assignment) {
    await this.add({
      equipmentId: equipmentId,
      driverId: assignment.id
    })
  }

  async deleteAssignments(assignments) {
    for (let i = 0; i < assignments.length; i++) {
      await this.deleteAssignmentById(assignments[i])
    }
  }

  async readAssignments(query) {
    let response = await this.$client.readAssignments(query)

    return response
  }

  async deleteAssignmentById(assignmentId) {
    let response = await this.$client.deleteAssignmentById(assignmentId)

    return response
  }
}
