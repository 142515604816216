<template>
  <div id="upload-modal" class="upload-modal vue-modal">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3 class="title">{{ title }}</h3>
      </div>
      <div class="content">
        <div class="left-column">
          <div v-if="!file" class="no-file">
            <div class="no-file-label">
              No File
              <br />Uploaded
            </div>
            <label for="file-upload" class="custom-file-upload">Upload</label>
            <input id="file-upload" ref="myFiles" @change="uploadFile" type="file" accept="*" style="display: none;" />
            <v-tooltip top close-delay="600" :dark="true">
              <template>
                <label for="file-upload" class="custom-file-upload">New File</label>
              </template>
              <span>Max File Upload Size 10MB</span>
            </v-tooltip>
          </div>
          <div v-if="file" class="file">
            <div v-if="uploading">
              <img class="spinner" src="../assets/loading.png" />
              <div class="uploading-label">Uploading File</div>
            </div>
            <div v-if="!uploading" class="done">
              <img class="preview" v-bind:src="previewImage" />
              <div class="title">{{ fileName }}</div>
              <div class="spacer"></div>
              <label for="file-upload" class="custom-file-upload">Replace</label>
              <input id="file-upload" ref="myFiles" @change="uploadFile" type="file" accept="*" style="display: none;" />
            </div>
          </div>
        </div>
        <div class="right-column">
          <div class="input-container">
            
            <text-input label="Name" v-model="inputValue"></text-input>

            

            <date-picker v-model="dateFormatted" valueType="format" placeholder="Expiration Date" title-format="MM/DD/YYYY"
              v-on="on"></date-picker>
            



            <div class="multiselect-container">
              <multiselect v-if="!isCompany" class="multi-select owner-multi-select" label="name" track-by="id"
                id="owner-select" v-model="owner.selected" placeholder selectLabel deselectLabel selectedLabel
                :showNoResults="true" :options="owner.options" :option-width="237" :option-height="40" :searchable="true"
                :preserveSearch="false" :allowEmpty="true" :hide-selected="false" open-direction="bottom"
                @open="isAssigned(true)" @close="isAssigned(false)">
                <template v-slot:noResult>
                  <div class="no-results">No results found</div>
                </template>
              </multiselect>
              <span v-on:click="focusMultiselect" v-if="!isCompany" v-bind:class="{ shiftUp: assigned }"
                class="assignments-label">{{ assigned ? 'ASSIGN TO' : 'Assign To' }}
              </span>
            </div>
          </div>
          <secondary-button v-bind:label="uploadModal.cancelText" v-bind:clicked="cancel"></secondary-button>
          <primary-button label="Save" v-bind:clicked="confirm" v-bind:disabled="uploadModalDisabled"></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'UploadModal',
  components: { Multiselect, TextInput, PrimaryButton, SecondaryButton, DatePicker },
  props: ['uploadModal'],
  data() {
    return {
      title: '',
      inputValue: '',
      // showDatePicker: false,
      // date: new Date().toISOString(),
      date: null,
      dateFormatted: '',
      menu1: false,
      menu2: false,
      type: '',
      file: false,
      fileName: '',
      uploadedFileUrl: '',
      previewImage: '',
      uploading: false,
      isDriver: false,
      isCompany: false,
      assigned: false,
      owner: {
        options: []
      }
    }
  },
  created() {
    this.setUp()
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    uploadModalDisabled: function () {
      if (
        this.type &&
        (this.type.toLowerCase() === 'driver' ||
          this.type.toLowerCase() === 'trailer' ||
          this.type.toLowerCase() === 'truck')
      ) {
        return (
          !this.inputValue ||
          !this.file ||
          this.uploading ||
          !this.owner.selected ||
          !this.owner.selected.id
        )
      } else {
        return !this.inputValue || !this.file || this.uploading
      }
    }
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      this.date = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
    },
    async setUp() {
      try {
        this.type = this.uploadModal.type
        const companyId = this.$session.getUserData().currentCompany.id
        if (!this.uploadModal.title) {
          this.title = `New ${this.type} Document`
        } else {
          this.title = this.uploadModal.title
        }

        // if (this.uploadModal.expiration) {
        //   this.dateFormatted = this.uploadModal.expiration
        // }
        if (this.uploadModal.expiration) {
          this.dateFormatted = this.uploadModal.expiration;
        }

        this.isDriver = this.type === 'Driver'
        this.isCompany = this.type === 'Company'
        if (this.isDriver) {
          this.$events.$emit('showLoading')
          let userData = await this.$userStore.loadAll({
            companyId: companyId
          })
          this.owner.options = this.createOptions(userData, this.isDriver)
          this.$events.$emit('hideLoading')
        } else {
          let eqList = await this.$equipmentStore.loadAll(companyId)
          let optionList = eqList.filter((equipment) => {
            return equipment.type === this.type.toLowerCase()
          })

          this.owner.options = this.createOptions(optionList, this.isDriver)
        }

        if (this.uploadModal.defaultValue) {
          this.inputValue = this.uploadModal.defaultValue
        }
        if (this.uploadModal.uploadedFileUrl) {
          this.uploadedFileUrl = this.uploadModal.uploadedFileUrl
          this.file = true
          this.fileName = ''
          this.previewImage = this.getPreviewImage(this.uploadedFileUrl)
        }
      } catch (err) {
        this.$events.$emit('hideUploadModal')
        this.$events.$emit('error', err)
      }
    },
    createOptions: function (data, isDriver) {
      let options = []
      if (isDriver) {
        for (let driver of data) {
          let userIsDriver = false
          for (let i = 0; i < driver.roles.length; i++) {
            if (driver.roles[i].role === 'driver') {
              userIsDriver = true
              break
            }
          }
          if (userIsDriver) {
            options.push({
              name: driver.firstName + ' ' + driver.lastName,
              id: driver.id
            })
          }
        }
      } else {
        for (let equipment of data) {
          options.push({ name: equipment.title, id: equipment.id })
        }
      }
      if (this.uploadModal.ownerId) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].id === this.uploadModal.ownerId) {
            this.owner.selected = {
              name: options[i].name,
              id: options[i].id
            }
            this.assigned = true
            break
          }
        }
      }
      return options
    },
    confirm: async function () {
      if (this.uploadModal.callback) {
        this.uploadModal.callback(true, this)
      }
      this.$events.$emit('hideUploadModal')
    },
    cancel: async function () {
      if (this.uploadModal.callback) {
        this.uploadModal.callback(false, this)
      }
      this.$events.$emit('hideUploadModal')
    },
    uploadFile: async function (evt) {
      if (evt.target.files.length < 1) {
        return
      }
      try {
        this.uploadedFileUrl = ''
        this.file = true
        this.uploading = true
        let file = evt.target.files
        this.fileName = file[0].name
        const formData = new FormData()
        formData.append('file', file[0])
        formData.append('tags', 'browser_upload')
        formData.append(
          'upload_preset',
          this.$Config.get().cloudinary.uploadPreset
        )
        // Set the preview width and convert pdf to .png
        let res = await this.$client.uploadFile(formData)
        this.uploadedFileUrl = res.data.secure_url
        this.previewImage = this.getPreviewImage(res.data.secure_url)
        this.uploading = false
      } catch (err) {
        this.uploadedFileUrl = ''
        this.uploading = false
        this.file = false
      }
    },
    focusMultiselect() {
      document.getElementById('owner-select').focus()
    },
    isAssigned: function (open) {
      this.assigned = this.owner.selected || open
    },
    getPreviewImage: function (url) {
      let parts = url.split('/upload/')
      if (parts.length < 2) return ''
      parts[1] = parts[1].replace('.pdf', '.png')
      return parts[0] + '/upload/h_251/' + parts[1]
    }
  }
}
</script>

<style lang="scss">
@mixin labelStyle() {
  font-family: Barlow;
  color: #505050;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

#upload-modal {
  .box {
    .content {
      padding: 0;
      display: flex;
      justify-content: flex-start;

      .left-column {
        width: 359px;
        border-right: 1px solid #979797;

        .primary-button {
          margin-left: 0;
        }

        .no-file {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .no-file-label {
            @include labelStyle();
            font-size: 16px;
            font-weight: 500;
            color: #afb3bf;
          }
        }

        .file {
          height: 100%;
          display: flex;

          .uploading-label {
            width: 199px;
            margin-top: 8px;
            font-family: Barlow;
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            color: $greyish-brown;
          }
        }

        input[type='file'] {}

        .custom-file-upload {
          width: 116px;
          height: 51px;
          position: absolute;
          bottom: 25px;
          padding: 16px 23px;
          border-radius: 5px;
          border: none;
          outline: none;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          text-transform: uppercase;
          background-color: $light-navy;
          color: $white;
        }

        .custom-file-upload:hover {
          background-color: $mid-blue;
        }

        .spinner {
          margin-top: 102px;
          animation: spin 1s infinite linear;
          -webkit-animation: spin 1s infinite linear;
        }

        @keyframes spin {
          from {
            transform: scale(1) rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }

          to {
            transform: scale(1) rotate(360deg);
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
          }
        }

        .done {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          overflow: hidden;

          .title {
            position: absolute;
            top: 0px;
            left: 0;
            padding-top: 16px;
            width: 100%;
            height: 54px;
            // max-width: 199px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            text-shadow: 1px 1px 0 var(--black-trans);
            font-family: Barlow;
            font-size: 16px;
            color: $white;
            background-image: linear-gradient(to bottom,
                rgba(5, 42, 73, 0.5),
                rgba(5, 42, 73, 0.5));
          }

          .spacer {
            width: 199px;
            height: 192px;
          }

          .preview {
            position: absolute;
            top: 0;
            left: 0;
            height: 251px;
            background-color: lightgrey;
          }
        }
      }

      .mx-datepicker {
        position: relative;
        display: inline-block;
        width: 100%;
        padding-top: 30px;

        .mx-input-wrapper {
          .mx-input {
            border: none;
            border-bottom: 1px solid #505050;
            outline: none;
            color: #505050;
            font-family: Barlow;
          }
        }
      }




      .right-column {
        width: 371px;
        padding: 24px 25px;

        .theme--light {
          .v-label {
            @include labelStyle();

            &--active {
              text-transform: uppercase;
            }
          }
        }

        .v-input__prepend-outer {
          display: none;
        }

        .v-text-field>.v-input__control>.v-input__slot:after,
        .v-text-field>.v-input__control>.v-input__slot:before {
          border-color: $mid-blue;
        }

        .v-text-field>.v-input__control>.v-input__slot:before {
          border-color: #000;
        }

        .v-menu {
          position: relative;

          .v-date-picker-header__value {
            button {
              color: $mid-blue;
            }
          }

          .v-menu__content {
            position: absolute;
            top: 0 !important;
            left: 0 !important;
          }

          .v-btn--active {
            color: $mid-blue;
          }
        }

        .input-container {
          margin-bottom: 25px;

          &::after {
            content: '';
            width: 100%;
            height: auto;
            display: block;
            clear: both;
          }
        }

        .text-input {
          width: 100%;
        }


        .input-field {
          width: 100%;
        }


        .multiselect-container {
          min-height: 50px;
          position: relative;
        }

        .multiselect {
          width: 100%;
          position: relative;

          .no-results {
            color: #e91515;
          }

          .multiselect__option--highlight {
            background: #41b883;
            outline: none;
            color: #505050;
          }
        }

        .assignments-label {
          @include labelStyle();
          position: absolute;
          top: 20px;
          left: 5px;
          font-size: 16px;
          transition: top 200ms;

          &.shiftUp {
            top: 5px;
            font-size: 12px;
          }
        }

        .primary-button,
        .secondary-button {
          margin-right: 25px;
        }
      }
    }
  }
}
</style>
