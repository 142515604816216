/* eslint-disable no-undef */
import Util from './util.js'

export default class DocStore {
  constructor(client, events) {
    this.docs = new Map()
    this.$client = client
    this.$events = events
  }

  async get() {}

  async add(url, docType, docName, ownerId, companyId, expiration) {
    let doc = await this.$client.newDocument(
      url,
      docType,
      docName,
      ownerId,
      companyId,
      expiration
    )

    await this.loadDocs(null, docType, companyId)

    return doc.id
  }

  async edit(docId, url, docType, docName, ownerId, companyId, expiration) {
    await this.$client.editDocument(docId, docName, url, ownerId, expiration)

    await this.loadDocs(null, docType, companyId)
  }

  async delete(document, ownerType) {
    await this.$client.deleteDocumentById(document.id)

    await this.loadDocs(null, ownerType, document.companyId)
  }

  async loadDocsByOwnerId(ownerId, ownerType, query, readOnly = false) {
    let response = await this.$client.readDocsByOwnerId(
      ownerId,
      ownerType,
      query
    )

    if (readOnly && filters) {
      let queryArr = filters.query.split(' ')

      response.data.forEach((doc) => {
        doc.points = 0
        let docName = doc.docName.toLowerCase()
        doc.name = doc.docName

        if (query !== '') {
          queryArr.forEach((key) => {
            doc.points = doc.points + (docName.split(key).length - 1)
          })
        }
      })

      return Util.sortByKey(response.data, 'points', true)
    }

    if (!readOnly && filters) {
      let queryArr = null
      let docs = response.data

      if (query !== '') queryArr = query.split(' ')
      let docsMap = new Map()

      docsMap.set('driver', { totalDocs: 0, docs: [] })
      docsMap.set('truck', { totalDocs: 0, docs: [] })
      docsMap.set('trailer', { totalDocs: 0, docs: [] })
      docsMap.set('company', { totalDocs: 0, docs: [] })

      docs.forEach((doc) => {
        doc.points = 0

        if (queryArr) {
          queryArr.forEach((key) => {
            let docName = doc.docName.toLowerCase()

            doc.points = doc.points + (docName.split(key).length - 1)
          })
        }

        docsMap.get(doc.type).docs.push(doc)
        docsMap.get(doc.type).totalDocs++
      })

      this.docs.set(ownerId, docsMap)

      this.$events.$emit('documents_refresh', this.docs.get(ownerId))

      return this.docs.get(ownerId)
    }
  }

  async loadDocs(
    ownerId,
    ownerType,
    companyId,
    fileType,
    query,
    readOnly = false
  ) {
    let response = await this.$client.readDocs({
      ownerId,
      ownerType,
      companyId,
      fileType,
      query
    })

    if (readOnly) {
      let queryArr = query.split(' ')

      response.data.forEach((doc) => {
        doc.points = 0
        let docName = doc.docName.toLowerCase()
        doc.name = doc.docName

        if (query !== '') {
          queryArr.forEach((key) => {
            doc.points = doc.points + (docName.split(key).length - 1)
          })
        }
      })

      return Util.sortByKey(response.data, 'points', true)
    }

    if (!readOnly) {
      let docsMap = new Map()

      docsMap.set('driver', { totalDocs: 0, docs: [] })
      docsMap.set('truck', { totalDocs: 0, docs: [] })
      docsMap.set('trailer', { totalDocs: 0, docs: [] })
      docsMap.set('company', { totalDocs: 0, docs: [] })

      response.data.forEach((doc) => {
        docsMap.get(doc.type).docs.push(doc)
        docsMap.get(doc.type).totalDocs++
      })

      this.docs.set(ownerId, docsMap)

      let emitEvent = ownerType
        ? ownerType + '_documents_refresh'
        : 'documents_refresh'

      this.$events.$emit(emitEvent, this.docs.get(ownerId))
      return docsMap
    }
  }
}
