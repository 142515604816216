import Axios from 'axios'

import Config from '../config/config.js'

export default class Client {
  constructor(events) {
    this.userId = ''
    this.authToken = ''
    this.$cookies
    this.$events = events
    this.apiKey = Config.get().apiKey
  }

  setAuthSync(cookiesRef, userId, token) {
    this.$cookies = cookiesRef
    this.userId = userId
    this.authToken = token
  }

  // Ensures that the user has not signed in with another account on another tab
  verifySessionIntegrity() {
    let cookie = this.$cookies.get('pbfbt')
    if (cookie && cookie.u) {
      if (cookie.u !== this.userId) {
        let err = new Error('Session Error')
        err.status = 401
        throw err
      }
    } else {
      this.$events.$emit('auto-sign-out')
    }
  }

  refreshCookieSync() {
    if (this.$cookies) {
      let data = {
        t: this.authToken,
        u: this.userId
      }
      this.$cookies.remove('pbfbt')
      this.$cookies.set('pbfbt', data)
    }
  }

  async getPlanOffering() {
    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/plans`,
      headers: {
        api_key: this.apiKey
      }
    })
    return response.data.plan
  }

  async deleteUserById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/users/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async deleteRoleById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/roles/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async deleteDocumentById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/documents/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async deleteEquipmentById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/equipment/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async deleteAssignmentById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/assignment/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async updateCompanyById(id, body) {
    await Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/companies/${id}`,
      data: body,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
  }

  async deleteCompanyById(id) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/companies/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async updateUserById(id, body) {
    this.verifySessionIntegrity()
    return Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/users/${id}`,
      data: body,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    }).then(() => {
      this.refreshCookieSync()
    })
  }

  async verifyUser(verificationCode, password) {
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/verify_code`,
      data: {
        code: verificationCode,
        password: password
      },
      headers: {
        api_key: this.apiKey
      }
    })

    return response.data
  }

  async sendSms(uids) {
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/bulk_verify`,
      data: {
        uids,
        action: 'send-sms'
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    return response.data
  }

  async forceVerifyUser(uid) {
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/bulk_verify`,
      data: {
        uid,
        action: 'verify-one'
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    return response.data
  }

  async resendInvite(uid, role) {
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/bulk_verify`,
      data: {
        uid,
        role,
        action: 'send-invite'
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    return response.data
  }

  async bulkVerifyUsers(uids) {
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/bulk_verify`,
      data: {
        uids,
        action: 'verify-many'
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    return response.data
  }

  async updateRoleById(id, body) {
    this.verifySessionIntegrity()

    await Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/roles/${id}`,
      data: body,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
  }

  async readUser(id) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/users/${id}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
    return response.data
  }

  async readRoles(userId, companyId, role, pageLimit, pageOffset) {
    this.verifySessionIntegrity()
    let query = this.parseQueryParamsSync({
      userId,
      companyId,
      role,
      pageLimit,
      pageOffset
    })

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/roles${query}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()
    return response.data
  }

  async readUsers(filters) {
    this.verifySessionIntegrity()

    let query = this.parseQueryParamsSync(filters)
    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/users${query}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    let users = {
      totalUsers: response.headers['x-total-item-count'],
      data: response.data
    }

    return users
  }

  async readEquipment(companyId, filters) {
    this.verifySessionIntegrity()

    let query = this.parseQueryParamsSync(filters)

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/equipment/${companyId}${query}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    let users = {
      totalEquipment: response.headers['x-total-item-count'],
      data: response.data
    }

    return users
  }

  async readCompanies(filters) {
    this.verifySessionIntegrity()

    let query = this.parseQueryParamsSync(filters)

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/companies${query}`,
      params: {
        stats: true
      },
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    let companies = {
      totalCompanies: response.headers['x-total-item-count'],
      data: response.data
    }

    return companies
  }

  async readCompanyById(companyId) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/companies/${companyId}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async readUserCompanies(userId) {
    const headers = {
      authToken: this.authToken,
      api_key: this.apiKey
    }
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/user_companies/${userId}`,
      headers
    })

    this.refreshCookieSync()
    return response.data
  }

  async readDocs(filters) {
    this.verifySessionIntegrity()

    let querySearch = this.parseQueryParamsSync(filters)
    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/documents${querySearch}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    let docs = {
      totalDocs: response.headers['x-total-item-count'],
      data: response.data
    }

    return docs
  }

  async readUserRoles(userId) {
    this.verifySessionIntegrity()
    let roles = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/roles?userId=${userId}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()
    roles.userId = userId
    return roles.data
  }

  async deleteUserRole(id) {
    this.verifySessionIntegrity()
    let roles = await Axios.request({
      method: 'DELETE',
      url: `${Config.get().baseAPI}/roles/${id}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()
    return roles.data
  }
  async createUserRole(userObj) {
    this.verifySessionIntegrity()
    let roles = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/roles`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      },
      data: {
        userId: userObj.userId,
        role: userObj.role,
        companyId: userObj.companyId
      }
    })
    this.refreshCookieSync()
    return roles.data
  }

  async readDocsByOwnerId(filters) {
    this.verifySessionIntegrity()

    let querySearch = this.parseQueryParamsSync(filters)
    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/documents?${querySearch}`,
      headers: {
        authToken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    let docs = {
      totalDocs: response.headers['x-total-item-count'],
      data: response.data
    }

    return docs
  }

  async createSubscription(paymentToken, companyId, initialCharge) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/subscription`,
      data: {
        paymentToken: paymentToken,
        companyId: companyId,
        initialCharge: initialCharge
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async cancelSubscription(companyId) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/subscription/cancel`,
      data: {
        companyId: companyId
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async updatePaymentMethod(paymentToken) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/payment`,
      data: {
        paymentToken: paymentToken
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async readSubscription(companyId) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/subscription?companyId=${companyId}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async readInvoices() {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/subscription/invoices`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async createUser(user) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/users`,
      data: user,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async createCompany(name, managed) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/companies`,
      data: {
        name: name,
        managed: managed
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async newDocument(url, docType, docName, ownerId, companyId, expiration) {
    this.verifySessionIntegrity()
    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/documents`,
      data: {
        url: url,
        docName: docName,
        type: docType,
        ownerId: ownerId,
        companyId: companyId,
        expiration
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()
    return response.data
  }

  async editDocument(id, docName, url, ownerId, expiration) {
    this.verifySessionIntegrity()
    let response = await Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/documents/${id}`,
      data: {
        url: url,
        docName: docName,
        ownerId: ownerId,
        expiration
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async uploadFile(formData) {
    const url = `https://api.cloudinary.com/v1_1/${
      Config.get().cloudinary.cloudName
    }/upload`
    const config = {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }

    return Axios.post(url, formData, config)
  }

  async validateUsers(file, companyId) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/users/validate`,
      data: {
        value: file,
        companyId
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async importUsers(file, companyId) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/users/import`,
      data: {
        value: file,
        companyId
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  //Equipment

  async createEquipment(equipment) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/equipment`,
      data: equipment,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async updateEquipmentById(id, body) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'PUT',
      url: `${Config.get().baseAPI}/equipment/${id}`,
      data: body,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })
    this.refreshCookieSync()

    return response.data
  }

  async validateEquipment(file, companyId, type) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/equipment/validate`,
      data: {
        value: file,
        companyId,
        type
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  async importEquipment(file, companyId, type) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/equipment/import`,
      data: {
        value: file,
        companyId: companyId,
        type: type
      },
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response.data
  }

  //Assignments

  async createAssignment(assignment) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'POST',
      url: `${Config.get().baseAPI}/assignment`,
      data: assignment,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  async readAssignments(filters) {
    this.verifySessionIntegrity()

    let response = await Axios.request({
      method: 'GET',
      url: `${Config.get().baseAPI}/assignment?${this.parseQueryParamsSync(
        filters
      )}`,
      headers: {
        authtoken: this.authToken,
        api_key: this.apiKey
      }
    })

    this.refreshCookieSync()

    return response
  }

  parseQueryParamsSync(filters) {
    let query = ''
    if (filters) {
      let first = true
      for (let key in filters) {
        // eslint-disable-next-line
        if (filters.hasOwnProperty(key) && filters[key]) {
          if (first) {
            query += '?'
            first = false
          } else {
            query += '&'
          }
          query += key + '=' + filters[key]
        }
      }
    }
    return query
  }

  getSessionDuration() {
    return 60 * 30
  }
}
