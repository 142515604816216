export default class Config {
  static get() {
    return {
    apiKey: process.env.VUE_APP_API_KEY,
      baseAPI: process.env.VUE_APP_BASE_API,
      firebase: {
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        apiKey: process.env.VUE_APP_FIREBASE_AUTH_KEY,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID,
        projectId:process.env.VUE_APP_PROJECT_ID
      },
      cloudinary: {
        cloudName: process.env.VUE_APP_CLOUDINARY_NAME,
        uploadPreset: process.env.VUE_APP_CLOUDINARY_PRESET
      },
      stripe: {
        publicKey: process.env.VUE_APP_STRIPE_KEY
      },
      env: process.env.VUE_APP_ENV
    }
  }
}
