import Util from './util.js'

export default class UserStore {
  constructor(client, events) {
    this.$client = client
    this.$events = events
    this.totalUsers = 0
    this.users = []
    this.mapUsers = new Map()
  }

  async add(user) {
    let response = await this.$client.createUser(user)

    //await this.loadAll()

    return response.data.id
  }

  async loadAll(filters, readOnly = false, acceptCached, noReload) {
    try {
      if (acceptCached && this.totalUsers > 0) {
        this.$events.$emit('users_refresh', this.getCachedUsers())
        return this.getCachedUsers()
      }
      const response = await this.$client.readUsers(filters)

      if (!readOnly && response) {
        this.users.forEach((user) => {
          //create points based system for sorting
          user.points = 0
          if (filters) {
            if (filters.query !== '' && filters.query) {
              let firstName = user.firstName.toLowerCase()
              let lastName = user.lastName.toLowerCase()
              let email = user.email.toLowerCase()
              if (filters.query && typeof filters.query === 'string') {
                let queryArr = filters.query.split(' ')
                queryArr.forEach((key) => {
                  user.points = user.points + (firstName.split(key).length - 1)
                  user.points = user.points + (lastName.split(key).length - 1)
                  user.points = user.points + (email.split(key).length - 1)
                })
              }
            }
          }
          return Util.sortByKey(response.data, 'points', true)
        })
      }

      if (!readOnly) {
        this.users = response.data
        this.totalUsers = response.totalUsers
        this.users.forEach((user) => this.mapUsers.set(user.id, user))
        if (!noReload) {
          this.$events.$emit('users_refresh', {
            users: this.users,
            totalUsers: this.totalUsers
          })
        }

        return this.users
      }
    } catch (err) {
      this.$events.$emit('error', err)
    }
  }

  getCachedUsers() {
    return {
      users: this.users,
      totalUsers: this.totalUsers
    }
  }

  async deleteUserById(userId) {
    await this.$client.deleteUserById(userId)

    await this.loadAll()
  }

  async deleteRoleById(roleId) {
    await this.$client.deleteRoleById(roleId)
  }

  async getUserName(userID) {
    if (this.totalUsers == 0) {
      await this.loadAll()
    }

    let user = this.mapUsers.get(userID)

    return `${user.firstName} ${user.lastName}`
  }

  async getUser(userId) {
    let user = this.mapUsers.get(userId)

    return user
  }

  async updateUserById(id, body) {
    await this.$client.updateUserById(id, body)
    return id
  }

  async updateRoleById(id, body) {
    await this.$client.updateRoleById(id, body)

    return id
  }

  async validateUsers(file, companyId) {
    let validateResults = await this.$client.validateUsers(file, companyId)

    return validateResults
  }

  async importUsers(file, companyId) {
    let importResults = await this.$client.importUsers(file, companyId)

    this.loadAll({ companyId: companyId })

    return importResults
  }

  async verifyUser(verificationCode, password) {
    const verified = await this.$client.verifyUser(verificationCode, password)
    return verified
  }

  async manuallyVerifyUser(id) {
    const success = await this.$client.forceVerifyUser(id)
    return success
  }

  async readUserRoles(userId) {
    const roles = await this.$client.readUserRoles(userId)
    return roles
  }

  async deleteUserRole(id) {
    const roles = await this.$client.deleteUserRole(id)
    return roles
  }

  async createUserRole(userObj) {
    const roles = await this.$client.createUserRole(userObj)
    return roles
  }
}
