<template>
  <div id="app">
    <activity-notification />
    <support-modal v-if="supportModal.show"></support-modal>
    <component
      :name="this.$route.name"
      :is="layout"
      class="main-view"
      v-bind:class="appClass"
    >
      <router-view />
    </component>
    <user-modal
      v-if="userModal.show"
      v-bind:userModal="userModal.opts"
    ></user-modal>
    <bulk-actions-modal
      v-if="bulkActionsModal.show"
      v-bind:bulkActionsModal="bulkActionsModal.opts"
    ></bulk-actions-modal>
    <basic-modal
      v-if="basicModal.show"
      v-bind:basicModal="basicModal.opts"
    ></basic-modal>
    <upload-modal
      v-if="uploadModal.show"
      v-bind:uploadModal="uploadModal.opts"
    ></upload-modal>
    <import-modal
      v-if="importModal.show"
      v-bind:importModal="importModal.opts"
    ></import-modal>
    <company-modal
      v-if="companyModal.show"
      v-bind:companyModal="companyModal.opts"
    ></company-modal>
    <payment-modal
      v-if="paymentModal.show"
      v-bind:paymentModal="paymentModal.opts"
    ></payment-modal>
    <equipment-modal
      v-if="equipmentModal.show"
      v-bind:equipmentModal="equipmentModal.opts"
    ></equipment-modal>
    <subscription-matrix-modal
      v-if="subscriptionMatrixModal.show"
      v-bind:subscriptionMatrixModal="subscriptionMatrixModal.opts"
    ></subscription-matrix-modal>
    <invite-user-modal
      v-if="inviteUserModal.show"
      v-bind:inviteUserModal="inviteUserModal.opts"
    ></invite-user-modal>

    <!-- <img class="comp" src="./assets/comp.png" v-if="showComp"> -->
    <div class="loading" v-if="loading"></div>
    <img
      class="main-loading-spinner"
      src="./assets/loader.png"
      v-if="loading"
    />
  </div>
</template>

<script>
import UserModal from '@/modals/UserModal'
import SupportModal from '@/modals/SupportModal'
import ActivityNotification from '@/components/ActivityNotification.vue'
import BasicModal from '@/modals/BasicModal'
import BulkActionsModal from '@/modals/BulkActionsModal'
import UploadModal from '@/modals/UploadModal'
import ImportModal from '@/modals/ImportModal'
import CompanyModal from '@/modals/CompanyModal'
import PaymentModal from '@/modals/PaymentModal'
import EquipmentModal from '@/modals/EquipmentModal'
import SubscriptionMatrixModal from '@/modals/SubscriptionMatrixModal'
import InviteUserModal from '@/modals/InviteUserModal'

const defaultLayout = 'default'

export default {
  components: {
    ActivityNotification,
    UserModal,
    BulkActionsModal,
    BasicModal,
    SupportModal,
    UploadModal,
    ImportModal,
    CompanyModal,
    PaymentModal,
    EquipmentModal,
    SubscriptionMatrixModal,
    InviteUserModal
  },
  data() {
    return {
      showComp: false,
      loading: false,
      basicModal: {
        show: false,
        opts: {
          title: 'New Document',
          text: '',
          mode: 'input',
          confirmText: 'Ok',
          cancelText: 'Cancel'
        }
      },
      uploadModal: {
        show: false,
        opts: {
          title: 'New Document',
          text: '',
          confirmText: 'Ok',
          cancelText: 'Cancel'
        }
      },
      userModal: {
        show: false,
        opts: {}
      },
      bulkActionsModal: {
        show: false,
        opts: {}
      },
      supportModal: {
        show: false
      },
      importModal: {
        show: false,
        opts: {
          title: 'Bulk Member Import',
          confirmText: 'Check',
          cancelText: 'Cancel'
        }
      },
      companyModal: {
        show: false,
        opts: {
          title: 'Edit Company',
          confirmText: 'Check',
          cancelText: 'Cancel'
        }
      },
      paymentModal: {
        show: false,
        opts: {}
      },
      equipmentModal: {
        show: false,
        opts: {}
      },
      subscriptionMatrixModal: {
        show: false,
        opts: {
          mode: 'pricing'
        }
      },
      inviteUserModal: {
        show: false,
        opts: {}
      }
    }
  },
  created() {
    this.$events.$on('showBasicModal', (opts) => {
      if (!opts.confirmText) opts.confirmText = 'OK'
      if (!opts.cancelText) opts.cancelText = 'Cancel'
      this.basicModal.opts = opts
      this.basicModal.show = true
    })
    this.$events.$on('showInviteUserModal', (opts) => {
      this.inviteUserModal.opts = opts
      this.inviteUserModal.show = true
    })
    this.$events.$on(
      'hideInviteUserModal',
      () => (this.inviteUserModal.show = false)
    )
    this.$events.$on('showUploadModal', (opts) => {
      if (!opts.confirmText) opts.confirmText = 'OK'
      if (!opts.cancelText) opts.cancelText = 'Cancel'
      if (!opts.defaultValue) opts.defaultValue = ''
      this.uploadModal.opts = opts
      this.uploadModal.show = true
    })
    this.$events.$on('showUserModal', (opts) => {
      this.userModal.opts = opts
      this.userModal.show = true
    })
    this.$events.$on('showBulkActionsModal', (opts) => {
      this.bulkActionsModal.opts = opts
      this.bulkActionsModal.show = true
    })
    this.$events.$on('hideBulkActionsModal', () => {
      this.bulkActionsModal.show = false
    })
    this.$events.$on('showSupportModal', () => {
      this.supportModal.show = true
    })
    this.$events.$on('showImportModal', (opts) => {
      if (!opts.confirmText) opts.confirmText = 'Check'
      if (!opts.cancelText) opts.cancelText = 'Cancel'
      this.importModal.opts = opts
      this.importModal.show = true
    })
    this.$events.$on('showCompanyModal', (opts) => {
      if (!opts.confirmText) opts.confirmText = 'Check'
      if (!opts.cancelText) opts.cancelText = 'Cancel'
      this.companyModal.opts = opts
      this.companyModal.show = true
    })
    this.$events.$on('showPaymentModal', (opts) => {
      this.paymentModal.show = true
      this.paymentModal.opts = opts
    })
    this.$events.$on('showEquipmentModal', (opts) => {
      this.equipmentModal.show = true
      this.equipmentModal.opts = opts
    })
    this.$events.$on('showSubscriptionMatrixModal', (opts) => {
      this.subscriptionMatrixModal.show = true
      this.subscriptionMatrixModal.opts = opts
    })

    this.onKeyDown = this.onKeyDown.bind(this)
    document.addEventListener('keydown', this.onKeyDown)

    this.$events.$on('hideBasicModal', () => {
      this.basicModal.show = false
    })
    this.$events.$on('hideUploadModal', () => {
      this.uploadModal.show = false
    })
    this.$events.$on('hideUserModal', () => {
      this.userModal.show = false
    })
    this.$events.$on('hideSupportModal', () => {
      this.supportModal.show = false
    })
    this.$events.$on('hideImportModal', () => {
      this.importModal.show = false
    })
    this.$events.$on('hideCompanyModal', () => {
      this.companyModal.show = false
    })
    this.$events.$on('hidePaymentModal', () => {
      this.paymentModal.show = false
    })
    this.$events.$on('hideEquipmentModal', () => {
      this.equipmentModal.show = false
    })
    this.$events.$on('hideSubscriptionMatrixModal', () => {
      this.subscriptionMatrixModal.show = false
    })

    this.$events.$on('showLoading', () => {
      this.loading = true
    })

    this.$events.$on('hideLoading', (callback) => {
      this.loading = false
      if (callback) {
        return callback()
      }
    })

    this.$events.$on('error', async (err) => {
      this.loading = false

      let errMsg = 'Unknown Error'
      let status = err.status ? err.status : 500

      if (err.response && err.response.data && err.response.data.error) {
        errMsg = err.response.data.error
        status = err.response.status ? err.response.status : 500
      } else if (err.message) {
        errMsg = err.message
      }

      if (status === 401) {
        await this.$session.logOut()
        this.$router.push('/login')
      } else if (status === 402) {
        let currentCompany = this.$session.getUserData().currentCompany

        if (currentCompany.role === 'org_admin') {
          this.$events.$emit('showSubscriptionMatrixModal', {
            mode: 'required',
            callback: (addCard) => {
              if (addCard) {
                if (!currentCompany) {
                  this.$events.$emit('error', {
                    message:
                      'Something went wrong. Please refresh the page and try again.'
                  })
                }
                this.$events.$emit('showPaymentModal', {
                  mode: 'required',
                  companyId: currentCompany.id,
                  callback: () => {
                    window.location.reload()
                  }
                })
              }
            }
          })
        } else {
          this.$events.$emit('showBasicModal', {
            title: 'Payment Required',
            text: "Your organization's subscription has lapsed or has reached a limit. Please contact your organization's administrator.",
            mode: 'ok',
            confirmText: 'OK'
          })
        }
      } else {
        console.log(err)
        this.$events.$emit('showBasicModal', {
          title: 'Error',
          text: errMsg,
          mode: 'ok',
          confirmText: 'OK'
        })
      }
    })

    this.$events.$on('auto-sign-out', async () => {
      await this.$session.logOut()
      if (this.$session.isAuthorizedPathSync(this.$route.path)) {
        this.$router.push('/login')
      }
    })

    this.$events.$on('logged_out', async (args) => {
      if (!args.refresh) return

      setTimeout(() => {
        window.location.reload()
      }, 200)
    })
  },
  methods: {
    onKeyDown(e) {
      if (e.keyCode === 189) {
        // Disabled
        this.showComp = !this.showComp
      }
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    appClass() {
      if (
        this.userModal.show ||
        this.basicModal.show ||
        this.supportModal.show ||
        this.uploadModal.show
      ) {
        document.body.classList.add('modal-active')
        return 'blurred'
      }
      document.body.classList.remove('modal-active')
      return ''
    }
  }
}
</script>

<style lang="scss">
@import './scss/_main.scss';
@import './scss/_modal.scss';

#app {
  position: relative;

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: #434b57;
    z-index: 100;
  }

  .main-loading-spinner {
    position: fixed;
    left: calc(50vw - 30.5px);
    top: calc(50vh - 30.5px);
    animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
    z-index: 101;
  }

  .comp {
    position: fixed;
    top: 0;
    left: 0;
    width: 1920px;
    height: 800px;
    opacity: 0.5;
  }
}
</style>
