<template>
  <div
    :style="positioning"
    :class="arrow"
    id="item-menu"
    v-if="showDropdown"
    @mouseover="cancelClose"
    @mouseleave="debounceClose"
  >
    <div class="inner">
      <!-- Transparent box to cause mouse leave event to fire correctly -->
      <div class="pointer-capture"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  data() {
    return {
      showDropdown: false
    }
  },
  props: {
    top: {
      type: String,
      required: true
    },
    left: {
      type: String,
      required: true
    },
    arrow: {
      type: String,
      required: false
    }
  },
  methods: {
    openMenu: function () {
      this.showDropdown = true
    },
    closeMenu: function () {
      this.showDropdown = false
    },
    changeMenuStatus: function () {
      this.showDropdown = !this.showDropdown
    },
    cancelClose: function () {
      this.debounceClose.cancel()
    }
  },
  computed: {
    debounceClose() {
      return debounce(this.closeMenu, 300)
    },
    positioning() {
      return {
        top: this.top,
        left: this.left
      }
    }
  }
}
</script>

<style lang="scss">
#item-menu {
  position: absolute;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-content: center;
  background-color: $white;
  box-shadow: 0 0 8px 0 $warm-grey-trans;
  border: 1px solid $greyish-brown-2;
  border-radius: 3px;
  z-index: 50;

  &::before {
    content: '';
    position: absolute;
    top: -12.5px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $greyish-brown-2;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -12.5px;
  //   right: 10px;
  //   width: 0;
  //   height: 0;
  //   border-left: 12px solid transparent;
  //   border-right: 12px solid transparent;
  //   border-bottom: 12px solid $white;
  // }

  &::after {
    content: '';
    position: absolute;
    top: -10.5px;
    right: 11.25px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid $white;
  }

  &.left {
    &::before {
      right: unset;
      left: 15px;
    }
    &::after {
      right: unset;
      left: 11.25;
    }
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .pointer-capture {
    position: absolute;
    top: -68px;
    left: -10%;
    width: 120%;
    height: 68px;
    background-color: transparent;
  }

  a {
    text-decoration: none;
  }

  p {
    padding: 8px;
    color: $light-navy;
    opacity: 0.8;
    font-weight: 500;
    font-size: 16px;
    font-family: Barlow;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
