<template>
  <div class="support-modal">
    <div class="background-overlay"></div>
    <div id="support-box" class="box">
      <div id="support-box-inner" class="content">
        <secondary-button
          class="cancel"
          v-bind:label="'Cancel'"
          v-bind:clicked="cancel"
        ></secondary-button>
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryButton from '../components/SecondaryButton'

export default {
  name: 'UserModal',
  components: {
    SecondaryButton
  },
  props: [],
  created() {
    /* eslint-disable-next-line no-undef */
    hbspt.forms.create({
      portalId: '2493639',
      formId: 'de9d95d0-08c5-4e95-93d9-f1f2041fb9d0',
      target: '#support-box-inner'
    })
  },
  data() {
    return {
      name: '',
      email: '',
      question: '',
      help: {
        selected: {
          title: 'How can we help you?',
          id: 'blank'
        },
        options: [
          {
            title: "I'd like to join the waiting list",
            id: 'waiting_list'
          },
          {
            title: 'I have a question',
            id: 'question'
          }
        ]
      }
    }
  },
  methods: {
    cancel: async function () {
      this.$events.$emit('hideSupportModal')
    },
    submit: async function () {
      try {
        await this.$client.submitSupportForm()
      } catch (err) {
        this.$events.$emit('error', err)
      }
    }
  },
  computed: {
    submitDisabled() {
      if (
        !this.name ||
        !this.email ||
        !this.question ||
        this.help.selected.id === 'blank'
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.support-modal {
  .cancel {
    position: absolute;
    bottom: 53px !important;
    left: 150px !important;
  }

  .box {
    max-height: 100vh;
    overflow-y: auto;
    .box-inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .content {
      .double-row {
        height: 50px;
        margin-bottom: 32px;

        .text-input {
          float: left;
        }
        .text-input:first-child {
          margin-right: 32px;
        }
      }

      .single-row {
        height: 50px;
        margin-bottom: 31px;

        .text-area-input {
          width: 504px;
        }
      }

      .help-multi-select {
        width: 100%;
      }

      .modal-buttons {
        position: absolute;
        bottom: 24px;
        left: 0;
        width: 100%;

        .primary-button {
          margin-left: 17px;
        }
      }
    }
  }
}

.hs-form-iframe {
  max-width: 100%;
}

.hbspt-form {
  iframe {
    width: 100%;

    .hs-input {
      width: 100%;
    }
  }
}
</style>
