<template>
  <div class="info-page">
    <CustomNavBar />
    <div class="content-box">
      <h1
        class="information-title"
        :style="{ opacity: computedName === 'loading' ? '0' : '1' }"
      >
        {{ computedName }}
      </h1>
      <div
        v-if="name === 'My Account'"
        class="close"
        v-on:click="closePage()"
      ></div>
      <div class="information-body">
        <slot />
      </div>
    </div>
    <CustomFooter class="footer" />
  </div>
</template>

<script>
import CustomFooter from '@/components/CustomFooter'
import CustomNavBar from '@/components/CustomNavBar'

export default {
  components: {
    CustomFooter,
    CustomNavBar
  },
  props: ['name'],
  data() {
    return {
      currentCompanyName: 'loading',
      previousNonAccountPage: ''
    }
  },
  created() {
    let userData = this.$session.getUserData()
    if (userData.currentCompany) {
      this.currentCompanyName = userData.currentCompany.name
    }

    this.$events.$on('admin_updated', (data) => {
      if (data.currentCompany) {
        this.currentCompanyName = data.currentCompany.name
      }
    })
  },
  watch: {
    $route(to, from) {
      if (from.path.indexOf('account') === -1) {
        this.previousNonAccountPage = from.path
      }
    }
  },
  methods: {
    closePage: async function () {
      if (this.previousNonAccountPage) {
        this.$router.push(this.previousNonAccountPage)
        this.previousNonAccountPage = ''
      } else {
        try {
          this.$events.$emit('showLoading')
          await this.$session.loadData()
          this.$events.$emit('hideLoading')
          let userData = this.$session.getUserData()
          if (userData.isSuperAdmin) {
            this.$router.push('/admin/user')
          } else {
            await this.$session.setDefaultPage()
          }
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }
    }
  },
  computed: {
    computedName: function () {
      if (this.name === '-company_name-') {
        return this.currentCompanyName
      } else {
        return this.name
      }
    }
  }
}
</script>

<style lang="scss">
.info-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  display: flex;
  flex-direction: column;

  .content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex-grow: 1;

    .information-title {
      margin: 62px 0px 32px;
      font-family: Poppins;
      font-size: 52px;
      font-weight: 500;
      text-align: center;
      color: $greyish-brown;
    }

    .information-body {
      font-family: Barlow;
      font-size: 19px;
      line-height: 26px;
      color: $greyish-brown;
      max-width: 1170px;
      padding: 0px;
      margin-bottom: 65px;
    }

    @media (max-width: 1175px) {
      .information-body {
        width: auto;
        margin: 0 5%;
      }
    }

    @media (max-width: $mobile-width) {
      .information-title {
        font-size: 44px;
        line-height: 52px;
      }
    }

    .close {
      position: absolute;
      top: 80px;
      right: -194px;
      width: 19px;
      height: 19px;
      background-image: url('../assets/modal-close.png');
      cursor: pointer;
    }

    .close:hover {
      background-position: -19px 0;
    }
  }
}

//Override footer css
.info-page .footer {
  background-color: $white;

  p {
    color: $cool-grey;
  }

  .logo {
    background-image: url('../assets/logo-grey.png');
  }
}
</style>
