<template>
  <button class="secondary-button" v-on:click="click">{{ label }}</button>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: ['label', 'clicked'],
  methods: {
    click: function () {
      this.clicked()
    }
  }
}
</script>

<style lang="scss">
.secondary-button {
  height: 19px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $greyish-brown;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.secondary-button:hover {
  color: $mid-blue;
}
</style>
