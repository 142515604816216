<template>
  <div class="basic-modal vue-modal" v-bind:class="modalClass">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3 class="basicModalTitle">{{ basicModal.title }}</h3>
      </div>
      <div class="content">
        <div v-if="basicModal.mode === 'confirm'">
          <img src="../assets/warning.png" />
          <p
            v-if="basicModal.html"
            class="primary-text"
            v-html="basicModal.text"
          ></p>
          <p v-else class="primary-text">{{ basicModal.text }}</p>
          <p v-if="basicModal.secondaryText" class="secondary-text">
            {{ basicModal.secondaryText }}
          </p>
          <secondary-button
            v-if="!basicModal.noCancel"
            v-bind:label="basicModal.cancelText"
            v-bind:clicked="cancel"
          ></secondary-button>
          <primary-button
            v-bind:class="{ wide: basicModal.noCancel }"
            v-bind:label="basicModal.confirmText"
            v-bind:clicked="confirm"
          ></primary-button>
        </div>
        <div v-if="basicModal.mode === 'ok'">
          <p>{{ basicModal.text }}</p>
          <primary-button
            v-bind:label="basicModal.confirmText"
            v-bind:clicked="cancel"
          ></primary-button>
        </div>
        <div v-if="basicModal.mode === 'input'">
          <text-input label="Name" v-model="inputValue"></text-input>
          <secondary-button
            v-bind:label="basicModal.cancelText"
            v-bind:clicked="cancel"
          ></secondary-button>
          <primary-button
            v-bind:label="basicModal.confirmText"
            v-bind:clicked="confirm"
            v-bind:disabled="!inputValue"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
export default {
  name: 'BasicModal',
  components: { TextInput, PrimaryButton, SecondaryButton },
  props: ['basicModal'],
  data() {
    return {
      inputValue: '',
      file: false,
      fileName: ''
    }
  },
  created() {
    if (this.basicModal.inputValue) {
      this.inputValue = this.basicModal.inputValue
    }
  },
  methods: {
    confirm: async function () {
      if (this.basicModal.callback) {
        this.basicModal.callback(true, this)
      }
      this.$events.$emit('hideBasicModal')
    },
    cancel: async function () {
      if (this.basicModal.callback) {
        this.basicModal.callback(false, this)
      }
      this.$events.$emit('hideBasicModal')
    }
  },
  computed: {
    modalClass: function () {
      let classVal = this.basicModal.mode + '-modal'
      if (this.basicModal.secondaryText) {
        classVal += ' show-secondary-text'
      }
      return classVal
    }
  }
}
</script>

<style lang="scss">
.basic-modal {
  .box {
    .content {
      .primary-text {
        margin-top: 32px;
      }

      .secondary-text {
        margin-top: 24px;
      }
    }
  }
}
.basic-modal.input-modal {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    .content {
      .text-input {
        width: 100%;
        margin-bottom: 100px;
      }
      .primary-button {
        margin-left: 17px;
      }
    }
  }
}
.basic-modal.ok-modal p,
.basic-modal.confirm-modal p {
  font-family: Barlow;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: $greyish-brown;
}
.basic-modal.confirm-modal {
  .box {
    width: 498px;
    p {
      padding: 0 10px;
      font-family: Barlow;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      text-align: center;
      line-height: normal;
      color: $greyish-brown;
    }
    .primary-button {
      margin: 32px 0px 0px 17px;
    }

    .wide {
      width: 189px;
    }
  }
}
.basic-modal.confirm-modal.show-secondary-text {
  .box {
    width: 498px;
  }
}
</style>
