<template>
  <div class="text-input">
    <label v-bind:class="labelClass">Phone</label>
    <div class="input-box">
      <input
        type="tel"
        v-bind:value="formatPhoneNumber(value)"
        v-bind:placeholder="labelClass === '' ? label : ''"
        @click="focusInput()"
        @blur="blurInput()"
        v-on:input="updateValue($event.target.value)"
        v-on:keypress="validateInput($event)"
        maxlength="10"
        v-bind:disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocused: false
    }
  },
  name: 'PhoneInput',
  props: ['value', 'label', 'type', 'maxLength', 'disabled'],
  methods: {
    updateValue: function (value) {
      if (value.startsWith('+1')) {
        value = value.substring(2)
      }
      console.log(value)

      this.$emit('input', value)
    },
    focusInput: function () {
      this.isFocused = true
    },
    blurInput: function () {
      this.isFocused = false
    },

    validateInput: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    formatPhoneNumber: function (value) {
      if (!value) return ''
      value = value.toString()
      if (value.length == 12 && value.startsWith('+1')) {
        return value.substring(2)
      } else {
        return value
      }
    }
  },
  computed: {
    labelClass() {
      if ((this.value && this.value.length > 0) || this.isFocused) {
        return 'show'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.text-input {
  width: 237px;
  height: 50px;
  text-align: left;

  label {
    width: 100%;
    height: 14px;
    font-family: Barlow;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: $greyish-brown;
    text-transform: uppercase;
    opacity: 0;
  }
  label.show {
    opacity: 1;
  }

  .input-box {
    display: block;
    width: 100%;
    height: 36px;

    input {
      width: 100%;
      height: 100%;
      padding: 0 3px;
      border: none;
      border-bottom: solid 1px $greyish-brown;
      outline: none;
      font-family: Barlow;
      font-size: 16px;
      color: $greyish-brown;
    }

    input[type='text']::placeholder {
      font-family: Barlow;
      font-size: 16px;
      color: $greyish-brown;
    }
  }
}
</style>
