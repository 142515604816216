<template>
  <button
    class="primary-button"
    v-bind:disabled="disabled"
    v-bind:class="buttonClass"
    v-on:click="click"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: ['label', 'disabled', 'clicked'],
  methods: {
    click: function () {
      this.clicked()
    }
  },
  computed: {
    buttonClass() {
      if (this.disabled) {
        return 'disabled'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.primary-button {
  width: 116px;
  height: 51px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  background-color: $light-navy;
  color: $white;
}

.primary-button:hover {
  background-color: $mid-blue;
}

.primary-button.disabled {
  background-color: #e7e7e7;
  color: $greyish-brown;
}
</style>
