<template>
  <div class="equipment-modal vue-modal">
    <div v-show="!submitting">
      <div class="background-overlay"></div>
      <div class="box">
        <div class="header">
          <h3 class="title">{{ equipmentModal.title }}</h3>
        </div>
        <div class="content">
          <div class="double-row">
            <text-input
              class="unit-number"
              label="Unit Number"
              v-model="unitNumber"
            ></text-input>
          </div>
          <div class="double-row">
            <multiselect
              class="multi-select assignment-multi-select"
              label="title"
              id="assignment-select"
              track-by="id"
              v-model="assignments.selected"
              selectLabel
              deselectLabel
              selectedLabel
              placeholder
              tagPlaceholder
              select-label
              :showNoResults="true"
              :options="assignments.options"
              :option-width="237"
              :option-height="40"
              :allowEmpty="true"
              :multiple="false"
              open-direction="bottom"
              @select="adjustHeight"
              @remove="adjustHeight"
            >
              <template v-slot:noResult>
                <div class="no-results">No results found</div>
              </template>
            </multiselect>
            <div class="search" v-on:click="focusMultiselect"></div>
            <span class="assignments-label">ASSIGN TO (OPTIONAL)</span>
          </div>
          <div class="button-row">
            <secondary-button
              label="Cancel"
              v-bind:clicked="cancel"
            ></secondary-button>
            <primary-button
              label="Save"
              v-bind:clicked="confirm"
              v-bind:disabled="formDisabled"
            ></primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'

export default {
  name: 'EquipmentModal',
  components: { Multiselect, TextInput, PrimaryButton, SecondaryButton },
  props: ['equipmentModal', 'companyId'],
  data() {
    return {
      submitting: false,
      unitNumber: '',
      boxHeight: 416,
      marginTop: 102,
      assignments: {
        selected: '',
        options: []
      }
    }
  },
  created() {
    try {
      this.$events.$emit('showLoading')
      this.unitNumber = this.equipmentModal.unitNumber || ''
      this.assignments.selected = this.equipmentModal.assignments || ''

      this.$events.$on('users_refresh', (data) => {
        let userOptions = []
        for (let i = 0; i < data.totalUsers; i++) {
          let roles = data.users[i].roles
          let isDriver = false
          for (let x = 0; x < roles.length; x++) {
            if (roles[x].role === 'driver') {
              isDriver = true
              break
            }
          }
          if (isDriver) {
            userOptions.push({
              title: data.users[i].firstName + ' ' + data.users[i].lastName,
              id: data.users[i].id
            })
          }
        }
        this.assignments.options = userOptions
        this.$events.$emit('hideLoading')
      })

      this.getUsers(this.equipmentModal.companyId)
    } catch (err) {
      this.$events.$emit('error', err)
    }
  },
  methods: {
    getUsers: async function (companyId) {
      await this.$userStore.loadAll({ companyId: companyId }, false, true)
    },
    adjustHeight() {
      setTimeout(() => {
        let wrap = document.querySelector(
          '.equipment-modal .box .content .double-row .multiselect .multiselect__tags-wrap'
        )
        let height = 0
        if (wrap) {
          height = wrap.getBoundingClientRect().height
        }
        this.boxHeight = Math.max(379 + height, 416)
        this.marginTop = Math.max(65 + height, 102)
      }, 50)
    },
    focusMultiselect() {
      document.getElementById('assignment-select').focus()
    },
    confirm: async function () {
      if (this.equipmentModal.callback) {
        this.submitting = true
        this.equipmentModal.callback(true, {
          unitNumber: this.unitNumber,
          assignments: this.assignments.selected
        })
      }

      this.$events.$emit('hideEquipmentModal')
    },
    cancel: async function () {
      if (this.equipmentModal.callback) {
        this.equipmentModal.callback(false, this)
      }
      this.$events.$emit('hideEquipmentModal')
    }
  },
  computed: {
    formDisabled() {
      return this.unitNumber == ''
    }
  }
}
</script>

<style lang="scss">
.equipment-modal {
  .box {
    .header .title {
      height: 73px !important;
      line-height: 73px !important;
      max-width: 560px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 28px !important;
    }

    .content {
      padding: 22px 32px;

      .double-row {
        height: 50px;
        margin-bottom: 32px;

        .unit-number {
          width: 506px;
        }

        .text-input {
          float: left;
        }
        .text-input:first-child {
          margin-right: 32px;
        }

        .multiselect {
          margin-top: 4px;
          width: 506px;

          .no-results {
            color: #e91515;
          }

          .multiselect__option--highlight {
            background: #41b883;
            outline: none;
            color: #505050;
          }

          .multiselect__tags {
            padding-left: 32px;
          }

          .multiselect__tag {
            background-color: white;
            font-size: 16px;
            font-family: Barlow;
            color: #505050;
            border: 1px solid $light-navy;
            border-radius: 6px;
            height: 30px;
            padding-top: 5px;

            .multiselect__tag-icon {
              cursor: pointer;
              margin-left: 7px;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              font-style: initial;
              width: 22px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.2s ease;
              border-radius: 5px;
              background-color: white;
            }

            .multiselect__tag-icon:after {
              content: 'x';
              color: #124e81;
              background-color: white;
              font-family: Nunito;
              padding-bottom: 2px;
            }

            .multiselect__tag-icon:hover {
              background-color: white;
            }

            .multiselect__tag-icon:hover:after {
              color: #afb3bf;
            }
          }

          .multiselect__tags-wrap {
            position: absolute;
            left: 0px;
            top: 74px;
            max-height: 111px;
            overflow-y: auto;
          }
        }
        .search {
          position: absolute;
          top: 24px;
          width: 24px;
          height: 24px;
          background-image: url(../assets/search.png);
          cursor: pointer;
        }
        .assignments-label {
          position: absolute;
          top: 0px;
          left: 0px;
          font-family: Barlow;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $greyish-brown;
          text-transform: uppercase;
        }
      }

      .double-row:nth-child(2) {
        margin-bottom: 24px;
      }

      .double-row.last {
        margin-bottom: 10px;
      }

      .single-row {
        height: 50px;
        margin-bottom: 31px;

        .text-input {
          width: 100%;
        }
      }

      .single-row.results {
        margin-bottom: 28px;
      }

      .button-row {
        .primary-button {
          margin-left: 17px;
        }
        margin-top: 32px;
      }

      .new-company-label,
      .existing-company-label {
        display: none;
        position: absolute;
        right: 0;
        top: 23px;
        font-family: Barlow;
        font-size: 13px;
        font-weight: 500;
        text-align: right;
        color: $light-navy;
        text-transform: uppercase;
      }

      .no-results {
        .multiselect__content-wrapper {
          display: none;
        }
      }

      .new-company {
        .new-company-label {
          display: block;
        }
      }
      .existing-company {
        .existing-company-label {
          display: block;
        }
      }
    }
  }
}
</style>
