import Vue from 'vue'
import Vuetify from 'vuetify'
import App from './App.vue'
import router from './router/index.js'
import Session from './model/session.js'
import Config from './config/config.js'
import Client from './service/client.js'
import Util from './service/util.js'
import UserStore from './service/userStore.js'
import CompanyStore from './service/companyStore.js'
import DocStore from './service/DocStore'
import EquipmentStore from './service/equipmentStore'
import AssignmentStore from './service/assignmentStore'
import SubscriptionStore from './service/subscriptionStore'
import VueCookies from 'vue-cookies'
import Notifications from 'vue-notification'
import moment from 'moment'

import './registerServiceWorker'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import Default from './layouts/Default'
import Information from './layouts/Information'

import 'vuetify/dist/vuetify.min.css'

Vue.prototype.$Config = Config
Vue.prototype.$moment = moment

Vue.component('default-layout', Default)
Vue.component('information-layout', Information)
Vue.use(Notifications)
Vue.use(VueCookies)
Vue.use(Vuetify)
let app = ''

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.prototype.$util = new Util()
Vue.prototype.$firebase = firebase
Vue.prototype.$events = new Vue()
Vue.prototype.$client = new Client(Vue.prototype.$events)
Vue.prototype.$session = new Session(
  firebase,
  Vue.prototype.$client,
  Vue.prototype.$events,
  router
)
Vue.prototype.$userStore = new UserStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)
Vue.prototype.$companyStore = new CompanyStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)
Vue.prototype.$docStore = new DocStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)
Vue.prototype.$equipmentStore = new EquipmentStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)
Vue.prototype.$assignmentStore = new AssignmentStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)
Vue.prototype.$subscriptionStore = new SubscriptionStore(
  Vue.prototype.$client,
  Vue.prototype.$events
)

Vue.config.productionTip = false

// Set up VueCookies
try {
  Vue.prototype.$session
    .initializeVueCookies(Vue.prototype.$cookies)
    .catch((err) => {
      Vue.prototype.$events.$emit('error', err)
    })
    .then(() => {
      // Vue.prototype.$events.$emit('hideLoading')
    })
} catch (err) {
  Vue.prototype.$events.$emit('error', err)
}

router.beforeEach((to, from, next) => {
  const currentUser = Vue.prototype.$session.isSignedIn()

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (!currentUser && requiresAuth) {
    next('login')
  } else if (currentUser && to.fullPath === '/') {
    next('/admin/user')
  } else {
    next()
  }
})

Vue.prototype.$session.firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: (h) => h(App)
    }).$mount('#app')
  }
})
