<template>
  <div class="footer">
    <div class="logo" v-on:click="goHome()">home</div>
    <a href="https://permitbook.com/support" target="_blank">
      <p class="link">Support</p>
    </a>
    <a href="https://permitbook.com/faq" target="_blank">
      <p class="link">FAQ</p>
    </a>
    <a href="https://permitbook.com/privacy" target="_blank">
      <p class="link">Privacy Policy</p>
    </a>
    <a href="https://permitbook.com/terms" target="_blank">
      <p class="link">Terms &amp; Conditions</p>
    </a>
    <div class="copyright">
      <p>© {{ showYear() }} Glostone</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomFooter',
  props: [],
  data() {
    return {
      isSuperAdmin: true,
      currentCompany: null
    }
  },
  created() {
    let userData = this.$session.getUserData()
    this.refreshAdmin(userData)

    this.$events.$on('admin_updated', (data) => {
      this.refreshAdmin(data)
    })
  },
  methods: {
    goHome: async function () {
      if (this.isSuperAdmin) {
        this.$router.push('/admin/user')
      } else {
        if (this.currentCompany) {
          this.$router.push(
            '/org/' + this.currentCompany.id + '/management/members'
          )
        } else {
          try {
            this.$events.$emit('showLoading')
            await this.$session.loadData()
            await this.$session.setDefaultPage()
            this.$events.$emit('hideLoading')
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      }
    },
    showYear() {
      return new Date().getFullYear()
    },
    showSupport() {
      this.$events.$emit('showSupportModal')
    },
    refreshAdmin: function (data) {
      if (!data.dataLoaded) return

      this.isSuperAdmin = data.isSuperAdmin
      if (data.currentCompany) {
        this.currentCompany = data.currentCompany
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  min-height: 100px;
  width: 100%;
  background-color: $black-trans;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 32px;

  p {
    line-height: 19px;
    color: $white;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }

  .link {
    margin-left: 32px;
    margin-top: 7px;
    cursor: pointer;
  }

  .logo {
    width: 40px;
    height: 40px;
    outline: none;
    border: none;
    color: $transparent;
    background-color: transparent;
    background-image: url('../assets/logo-color.png');
    background-size: cover;
    cursor: pointer;
    pointer-events: all;
  }
  .copyright {
    margin-top: 7px;
    cursor: default;
    justify-self: flex-end;
    margin-left: auto;
  }
}
@media (orientation: landscape) and (max-width: $landscape-width) {
  .footer {
    margin-top: 50px;
  }
}

@media (max-width: $mobile-width) {
  .footer {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;

    .copyright {
      margin-top: 30px;
      margin-left: 0px;
      justify-self: ceer;
    }

    .link {
      margin: 10px 0px;
    }
  }
}
</style>
